<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { useRoute } from 'vue-router';
import { anykrowdApi } from '@/api/anykrowdApi';
import useTenant from '@/shared/composables/useTenant';
import { data } from 'autoprefixer';
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

// Init
const { t } = useI18n();
const assetStore = useAssetsStore();
const route = useRoute();
const voucherCode = route.params.id.toString();

const voucherMetaData: any = ref(null);
const voucherAmount = ref('');
const voucherRedeemed = ref(false);
const error = ref('');

// onMountedHook
onMounted(async () => {
	const { data } = await anykrowdApi.get('/vouchers/warranty-voucher-value/' + voucherCode, false);
	if (data && !data.error) {
		try {
			voucherMetaData.value = data.voucher_meta_data;
			voucherAmount.value = parseFloat(data.voucher_meta_data.full_fiat_value).toFixed(2);
			voucherRedeemed.value = data.voucher_meta_data['status'] === 'redeemed';
		} catch (e) {
			console.error(e);
		}
	}

	if (data.error === 'invalid_voucher_code') {
		error.value = 'invalid_voucher_code';
	}
});
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer">
					<router-link to="/" class="flex items-center justify-center">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</router-link>
				</div>
			</template>
			<template #center>
				<div class="flex justify-center w-full text-center font-sourcesanspro-semibold text-l2">
					{{ t('wallet.voucher') }}
				</div>
			</template>
		</TopBar>

		<div>
			<div class="px-[34px] icon flex justify-center mb-4">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
			</div>
		</div>

		<div class="p-6 mx-auto mb-4 bg-white rounded-lg shadow-md">
			<div class="flex flex-col">
				<p v-if="error === 'invalid_voucher_code'" class="text-center text-gray-900 black font-sourcesanspro-bold">The voucher code is invalid</p>

				<template v-if="!error && voucherMetaData">
					<div class="mb-4 text-center">
						<div class="text-sm text-gray-500">{{ t('wallet.voucher_amount') }}</div>
						<div class="text-2xl font-bold text-gray-900">{{ voucherAmount }} EUR</div>
					</div>

					<router-link v-if="!voucherRedeemed && voucherMetaData?.within_redeemable_time" :to="`/wallet/refunds/${voucherCode}/guestrequest?isVoucher=true`" class="mx-auto">
						<ion-button class="w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin mx-auto" shape="round">
							{{ t('wallet.request_refund') }}
						</ion-button>
					</router-link>

					<p v-else-if="!voucherMetaData?.within_redeemable_time" class="text-center text-gray-900 black font-sourcesanspro-bold">
						The voucher can be redeemed between
						{{ voucherMetaData?.valid_at }}
						and
						{{ voucherMetaData?.expires_at }}
					</p>

					<p v-else-if="voucherRedeemed" class="text-center text-gray-900 black font-sourcesanspro-bold" v-html="tenantConfig?.warranty_settings_qr_voucher_redeemed_message ?? 'The voucher has been redeemed'"></p>
				</template>
			</div>
		</div>
	</ion-content>
</template>

<style scoped></style>
