<script setup lang="ts">
import { PropType, ref, watch } from 'vue';
import { IonAccordionGroup, IonAccordion, IonButton, IonIcon } from '@ionic/vue';
import {
	addOutline,
	chevronDownOutline,
	chevronUpOutline,
	createOutline,
	removeOutline,
	trashOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Discount, DiscountType } from '@/shared/interfaces/discount';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import { Currency } from '@/shared/interfaces/balance';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { Product } from '@/shared/interfaces/tableSpot';
import { GenericProduct } from '@/shared/interfaces/genericProduct';

// Props
const props = defineProps({
	addMoreError: { type: String, default: '' },
	addMoreErrorDetail: { type: String, default: '' },
	canAddMore: { type: Boolean, default: false },
	canWriteAComment: { type: Boolean, default: false },
	class: { type: String },
	description: { type: String },
	discount: { type: Discount },
	expandable: { type: Boolean, default: true },
	id: { type: Number },
	comments: {
		type: Object as PropType<string[]>,
		required: false,
	},
	price: { type: Number, required: true },
	priceWithDiscount: { type: Number },
	currency: { type: Currency, required: true },
	productId: { type: Number, required: true },
	product: {
		type: Object as PropType<EventTicketType | EventComboTicketType | Product | GenericProduct>,
		required: true,
	},
	quantity: { type: Number, required: true },
	title: { type: String, required: true },
});

// Init
const { t } = useI18n();
const isOpen = ref(true);
const emit = defineEmits([
	'onWriteAComment',
	'onDecreaseProductQuantity',
	'onIncreaseProductQuantity',
	'onDeleteAComment',
]);
const { presentToast } = useToast();
const { getTenantConfigAndStyles } = useTenant();

// deleteComment
const deleteComment = (commentIndex: number) => {
	emit('onDeleteAComment', props.productId, commentIndex);
};

// writeAComment
const writeAComment = () => {
	emit('onWriteAComment');
};

// decreaseProductQuantity
const decreaseProductQuantity = (event: MouseEvent) => {
	event.stopPropagation();
	emit('onDecreaseProductQuantity', props.product);
};

// increaseProductQuantity
const increaseProductQuantity = (event: MouseEvent) => {
	event.stopPropagation();
	emit('onIncreaseProductQuantity', props.product);
};

// watch
watch(
	() => props.canAddMore,
	async (value, oldValue) => {
		if (!value) {
			await presentToast('top', t(props.addMoreError, { value: props.addMoreErrorDetail }), 5000, 'danger');
		}
	},
);
</script>

<template>
	<ion-accordion-group :class="props.class" :value="props.description ? $props.id?.toString() : ''">
		<ion-accordion
			class="backdrop-blur-xl bg-white/10 rounded-lg"
			:value="props.description ? $props.id?.toString() : ''">
			<div
				class="flex flex-col w-full justify-between px-3 py-2 cursor-pointer"
				slot="header"
				@click="expandable ? (isOpen = !isOpen) : null">
				<div class="flex w-full justify-between">
					<div class="flex items-center">
						<div class="flex items-center font-sourcesanspro-semibold text-lg24 mr-2" v-if="expandable">
							<ion-icon
								:icon="isOpen ? chevronDownOutline : chevronUpOutline"
								class="text-white"></ion-icon>
						</div>
						<div class="flex items-center">
							<div class="mr-2">
								<span class="font-sourcesanspro-semibold text-l2 capitalize">
									{{ props.title.toLowerCase() }}
								</span>
							</div>
						</div>
					</div>
					<div class="flex">
						<span
							class="font-sourcesanspro-bold text-lg"
							:class="[{ 'text-white/30': props.quantity === 0 }]">
							{{ props.currency?.prefix }}
							{{
								((props.discount ? props.priceWithDiscount : props.price)! * props.quantity!).toFixed(2)
							}}</span
						>
					</div>
				</div>
				<div class="flex justify-between">
					<div class="flex items-center justify-center">
						<div v-if="props.discount" class="flex items-center justify-center mr-2">
							<span class="font-sourcesanspro-bold text-sm text-white/30 line-through">
								{{ props.currency?.prefix }}{{ props.price.toFixed(2) }}
							</span>
						</div>
						<div
							v-if="props.discount"
							class="flex items-center justify-center mr-2 px-3 py-[1px] rounded-full bg-anykrowd-discount-green font-sourcesanspro-bold text-xs2">
							<div v-if="props.discount.type === DiscountType.FIXED">
								<span>{{ props.currency?.prefix }} {{ props.discount.amount.toFixed(2) }}</span>
							</div>
							<span v-if="props.discount.type === DiscountType.PERCENTAGE"
								>{{ props.discount.amount }} &percnt;</span
							>
						</div>
						<span class="font-sourcesanspro-bold text-base2 text-white">
							{{ props.currency?.prefix }}
							{{ props.discount ? props.priceWithDiscount?.toFixed(2) : props.price.toFixed(2) }}
						</span>
					</div>
					<div class="flex items-center justify-between w-[100px]">
						<div>
							<ion-button
								class="w-[32px] h-[32px] ion-no-padding ion-no-margin"
								fill="outline"
								style="z-index: 1000"
								@click="(event: MouseEvent) => decreaseProductQuantity(event)"
								:disabled="quantity === 0">
								<ion-icon :icon="removeOutline" class="text-white"></ion-icon>
							</ion-button>
						</div>
						<span class="font-sourcesanspro-semibold text-lg">{{ props.quantity }}</span>
						<div>
							<ion-button
								class="w-[32px] h-[32px] ion-no-padding ion-no-margin hover:bg-white hover:rounded"
								:class="[
									{
										'no-more-items': !canAddMore,
									},
								]"
								@click="(event: MouseEvent) => increaseProductQuantity(event)"
								:disabled="!canAddMore">
								<ion-icon :icon="addOutline" class="text-anykrowd-dark"></ion-icon>
							</ion-button>
						</div>
					</div>
				</div>
			</div>
			<div class="px-5 pb-4 font-sourcesanspro-regular text-sm text-white/50" v-if="expandable" slot="content">
				<div class="mb-4">
					{{ props.description }}
				</div>
				<div v-if="props.canWriteAComment" class="mb-4">
					<ion-button
						:disabled="props.quantity === 0"
						class="flex justify-left w-full comment"
						fill="outline"
						@click="writeAComment">
						<div class="flex w-full items-center justify-start text-white rounded">
							<ion-icon :icon="createOutline" class="mr-2"></ion-icon>
							<span class="text-sm">Write a comment...</span>
						</div>
					</ion-button>
				</div>
				<div
					v-for="(comment, idx) in props.comments"
					:key="idx"
					class="flex items-center justify-between mb-1 px-3 py-2 bg-white rounded-md">
					<!-- <span class="font-sourcesanspro-semibold text-base2 text-dark-blue">
          {{ props.message.title }}
        </span> -->
					<span class="font-sourcesanspro-regular text-sm text-[#1C1725]/50 max-w-[90%]">
						{{ comment }}
					</span>

					<ion-button
						class="w-[30px] h-[30px] ion-no-padding ion-no-margin transparent"
						@click="deleteComment(idx)">
						<ion-icon :icon="trashOutline" class="text-sm text-anykrowd-dark"></ion-icon>
					</ion-button>
				</div>
			</div>
		</ion-accordion>
	</ion-accordion-group>
</template>

<style lang="scss" scoped>
ion-button {
	&.comment {
		--border-color: #8b8892;
		--border-radius: 10px;
		--border-width: 1px;
	}
}
</style>
